.signup-form {
  max-width: 400px;
  margin: auto;
}

.error-message {
  color: red;
  font-size: 12pt;
  font-family: "Poppins", sans-serif;
}

.signup-form label {
  display: block;
  font-family: 'Rubik', sans-serif;
  font-size: 14pt;
  margin-bottom: 8px;
}

.signup-form input {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-size: 14pt;
}

.signup-form button {
  background-color: #7c27f2;
  color: #fff;
  padding: 10px;
  font-size: 16pt;
  border: #7c27f2;
  cursor: pointer;
  width: 250px;
  margin: 5px auto;
  border-radius: 8px;
  display: block;
}

.signup-form button:hover {
  background-color: #a965f4;
}

p {
  text-align: center;
  margin-top: 20px;
}

p a {
  color: #7c27f2;
}

h1 {
  font-family: "Poppins", sans-serif;
}
