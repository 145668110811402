.leaderboard-container {
    justify-content: center;
    align-items: center;
    height: 125vh; 
  }
  
  img  {
    align-self: center;
    padding: 40px;
    max-width: 80%;
  }

  .leaderboard-table {
    width: 100%;
    max-width: 600px; /* Set a maximum width if desired */
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .leaderboard-table th, .leaderboard-table td {
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    border: 6px solid #000000;
  }
  
  .leaderboard-table th:nth-child(1),
  .leaderboard-table td:nth-child(1) {
    min-width: 250px; /* Set the desired width for the NAME column */
  }

  .leaderboard-table th {
    background-color: #000000;
    color: white; /* Set text color for th */
    min-width: 130px;
  }
  
  /* Alternating row colors */
  .leaderboard-table tr:nth-child(odd) {
    background-color: #7C27F7;
    color: white;
    font-family: 'Poppins', sans serif;
    font-size: 14pt;
  }
  
  .leaderboard-table tr:nth-child(even) {
    background-color: #A965F4;
    color: white;
    font-size: 14pt;
    font-family: 'Poppins', sans-serif;
  }

  th  {
    font-family: 'Poppins', sans-serif;
    font-size: 14pt;
  }

  h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 14pt;
    text-align: center;
  }