.backButton {
    z-index: 1050;
    position: fixed;
    top: 10px; 
    left: 10px; 
    background-color: #00000000; 
    color: #7c27f2; 
    border: none;
    padding: 10px;
    border-radius: 4px; 
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    font-size: 2.9rem;
}
