.home-container {
    text-align: center;
    margin: 20px;
    margin-bottom: 80px;
  }
  
h1 {
    color: #7c27f2;
    font-size: 24pt;
    padding-top: 5px;
    padding-bottom: 20px;
  }
  
.logo {
  width: 65%; /* Looks good this size on larger screens */
  @media (max-width: 768px) {width: 90%;} /* This is for phone screens */
}

p {
    font-family: 'Poppins', sans-serif;
    /* font-size: 18px; */
    color: #ffffff;
    padding-top: 5px;
    padding-bottom: 0px;
  }
  
.btn-primary {
    background-color: #7c27f2;
    border-color: #7c27f2;
    color: #fff;
    text-align: center;
    font-size: 16pt;
    font-family: 'Poppins', sans-serif;
    justify-content: center;
    margin: 5px auto;
    padding: 10px;
    width: 250px; 
    transition: background-color 0.3s; 
}

.btn-primary:hover {
  background-color: #a965f4;
}
