.left-arrow,
.right-arrow {
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: #7c27f2;
}

/* Adjust positioning as needed */
.left-arrow {
  position: absolute;
  left: 10px;
}

.right-arrow {
  position: absolute;
  right: 10px;
}

.carousel-item {
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
}

.carousel-item img {
  max-height: 100%;
  width: auto; /* Allow the width to adjust based on the height while maintaining aspect ratio */
  max-width: 100%; 
  object-fit: cover; /* This ensures that the image covers the area without distortion */
  padding: 0px;
}

.placeholder {
  font-family: "Rubik", sans-serif;
}

.form-label {
  padding-top: 20px;
  padding-bottom: 5px;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 12pt;
}

h3 {
  font-family: "Rubik", sans-serif;
  font-size: 14pt;
}

h4 {
  font-family: "Poppins", sans-serif;
  font-size: 30pt;
}

.btn-primary {
  font-family: "Rubik", sans-serif;
  margin-top: 20px;
  padding: 12px;
  background: #7c27f2;
  border: #000000;
  font-size: 14pt;
}

.failed-guesses {
  font-family: "Poppins", sans-serif;
  padding-top: 40px;
  padding-bottom: 100px;
  color: #fff;
}

ul {
  font-family: "Rubik", sans-serif;
  font-size: 16pt;
}

form-control {
  font-size: 24pt;
}

#formGuessInput {
  padding: 10px;
}
