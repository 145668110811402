.login-form {
    max-width: 400px;
    margin: auto;
  }
  
  .login-form label {
    display: block;
    margin-bottom: 10px;
    font-family: 'Rubik', sans-serif;
    font-size: 14pt;
  }
  
  .login-form input {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    box-sizing: border-box;
  }
  
  .login-form button {
    background-color: #7C27F2;
    color: #fff;
    padding: 10px;
    font-size: 16pt;
    border: #7c27f2;
    cursor: pointer;
    width: 250px; 
    margin: 5px auto;
    border-radius: 8px;
    display: block;
  }
  
  .login-form button:hover {
    background-color: #A965F4;
  }
  
  p {
    font-family: 'Rubik', sans-serif;
    font-size: 14pt;
    text-align: center;
    margin-top: 20px;
  }
  
  p a {
    color: #7C27F2;
  }

  h1  {
    font-family: 'Poppins', sans-serif;
    text-align: center;
  }

  img {
    width: 200px;
    pointer-events: none;
  }
  
  