.contact-form {
    max-width: 400px;
    margin: auto;
    text-align: left;
  }
  
  .contact-form label {
    display: block;
    font-family: 'Rubik', sans-serif;
    font-size: 14pt;
    margin-bottom: 8px;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    font-family: 'Rubik', sans-serif;
    font-size: 14pt;
    margin: 5px auto;
    box-sizing: border-box;
  }
  
  .contact-form button {
    background-color: #7c27f2;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    font-size: 16pt;
    padding: 10px;
    border-color: #7c27f2;
    cursor: pointer;
    text-align: center;
    display: block;
    margin: 5px auto;
    width: 250px; 
    border-radius: 8px;
    margin-bottom: 150px;
  }
  
  .contact-form button:hover {
    background-color: #A965F4;
  }

  h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 44pt;
    }

  p {
    font-family: 'Rubik', sans-serif;
    font-size: 14pt;
  }

  
  