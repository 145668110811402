.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #000000;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #000000;
}

.border {
  background-color: #000000;
}

.margin {
  color: #000000;
}

.logo {
  display: block;
  width: 45%;
  margin: 0 auto;
  padding: 20px;
}

/* Media queries for different screen sizes */
@media (max-width: 768px) {
  /* For tablets and smaller devices */
  .logo {
    width: 80%;
    max-width: none;
  }
}

@media (max-width: 480px) {
  /* For mobile phones */
  .logo {
    width: 100%;
    max-width: none;
  }
}

.GameImage {
  display: block;
  margin: 0 auto;
}

.btn.primary:hover {
  background: #a965f4;
}

.btn-primary:bs-btn {
  font-size: 7rem;
  font-weight: 400;
}
