.image-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.image-container img {
  width: 100%;
  max-width: 400px;
  height: auto;
}

.text-container {
  width: 80%;
  margin: 0 10% 200px;
  text-align: left;
  font-size: 14pt;
}

@media (max-width: 600px) {
  .text-container {
    width: 90%;
    margin: 0 5% 25px;
  }
}

h1 {
  font-family: "Poppins", sans-serif;
  font-size: 24pt;
  text-align: left;
  margin: 20px;
}

.bold-text {
  font-weight: bold;
}

p {
  font-family: "Rubik", sans-serif;
  color: #ffffff;
  font-size: 14pt;
  text-align: left;
  margin: 50px;
}

.text-list {
  list-style: none;
  padding: 10px;
  font-size: 14pt;
}

.text-list li {
  margin-bottom: 15px; 
}

.play-link {
  text-decoration: none;
  color: white;
  transition: color 0.3s;
}

.play-link:hover {
  color: #a965f4;
}
